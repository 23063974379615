/* Custom Fonts */

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Book.ttf');
    font-weight: 400;
}

/* Default CSS */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: white !important;
    font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

.App {
    text-align: center;
}


p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

@media (min-width:1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}
p.success {
    color: lightgreen;
    margin-top: 10px;
}

p.danger {
    color: red;
    margin-top: 10px;
}
/************ Navbar Css ************/
nav.navbar {
    padding: 20px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
    padding: 10px 0;
    background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
    margin-right: 30px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 0 15px;
    font-size: 15px;
    opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}

.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 24px;
    font-size: 16px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    border-radius: 0;
    transition: 0.3s ease-in-out;
}
.navbar-text button span {
    z-index: 1;
}
.navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
    color: #121212;
}
.navbar-text button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
    top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}
body.navbar-collapse.show {
    overflow: hidden;
}

@media (max-width: 991.98px) {
    nav.navbar {
        padding: 20px 0;
    }
    nav.navbar .navbar-nav .nav-link.navbar-link {
        padding: 10px;
    }
    .navbar-text button {
        padding: 8px 20px;
        font-size: 14px;
        margin-left: 10px;
    }
    nav.navbar.expanded {
        background-color: #121212;
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #666;
    }
    .social-icon a {
        width: 30px;
        height: 30px;
    }
    .social-icon {
        margin-left: 0;
    }
    span.navbar-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;
    }
    .navbar-text button {
        padding: 6px 18px;
        font-size: 12px;
        margin-top: 18px;
        margin-left: 0;
    }
    .nav-link.navbar-link {
        background-color: #1b1b1b;
        color: white;
        border: 1px solid #1b1b1b;
        border-radius: 10px;
        font-size: 24px;
        padding: 3px 16px;
        margin: 4px 0;
        text-decoration: none;
    }
    .nav-link.navbar-link.active {
        background-color: #222;
        position: relative;
        border: 1px solid white;
    }
    .innovation-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .newsletter-bx {
        padding: 40px 60px !important;
    }
    .newsletter-bx h3 {
        font-size: 20px !important;
    }
    .footer img {
        width: 35% !important;
    }
}

@media (max-width: 768px) {
    .banner h1 {
        font-size: 30px !important;
        margin-bottom: 10px !important;
    }
    .banner p {
        font-size: 14px !important;
    }
    .banner img {
        height: 80%;
    }
    .skill {
        padding: 0 0 30px 0 !important;
    }
    .skill h2 {
        font-size: 30px !important;
    }
    .skill p {
        font-size: 14px !important;
        margin: 10px 0 25px 0 !important;
    }
    .skill-bx {
        padding: 30px 20px !important;
    }
    .image-gallery {
        justify-content: center !important;
    }
    .project {
        padding: 30px 0 !important;
    }
    .project h2 {
        font-size: 30px !important;
    }
    .project p {
        width: 90% !important;
        font-size: 14px !important;
        margin: 10px auto 25px auto !important;
    }
    .project .nav.nav-pills {
        width: 95% !important;
    }
    .project .nav.nav-pills .nav-link {
        font-size: 14px !important;
    }
    .innovation-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .innovation-img {
        height: 250px !important;
        margin-top: 0 !important;
    }
    .future h1 {
        font-size: 25px !important;
    }
    .future p {
        font-size: 14px !important;
        margin: 0 !important;
    }
    .card {
        flex: 100% !important;
    }
    .contact h2 {
        font-size: 30px !important;
        margin: 20px 0 !important;
    }
    .contact form {
        margin: 10px 10px 13px 10px !important;
    }
    .newsletter-bx {
        padding: 40px 40px !important;
    }
    .newsletter-bx h3 {
        font-size: 20px !important;
        margin-bottom: 20px;
    }
    .textalignmentbanner {
        min-height: 400px;
    }
}

@media (max-width: 575px) {
    .footer-logo {
        justify-content: center;
        margin-bottom: 10px;
    }
}

/************ Banner CSS ************/
.banner {
    margin-top: -60px;
    padding: 260px 0 100px 0;
    background-image: url('./assets/img/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 15px;
    margin-bottom: 16px;
    display: inline-block;
}
.banner h1 {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
}
.banner p {
    color: #aaa6a6;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
}
.textalignmentbanner {
    text-align: left;
}
.banner button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
}
.banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}
.banner button:hover svg {
    margin-left: 25px;
}
.banner img {
    animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
    border-right: 0.08em solid transparent;
    animation: blink-caret 1s step-end infinite;
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: #666;
    }
}

/************ Skills CSS ************/
.skill {
    padding: 0 0 50px 0;
    position: relative;
}
.skill-bx {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
}
.skill h2 {
    font-size: 45px;
    font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
}
.skill-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
}
.background-image-left {
    position: absolute;
    top: 28%;
    bottom: 0;
    left: 0;
    width: 40%;
    z-index: -1;
}

.react-multi-carousel-list{
    position: unset !important;
}

/************ Projects CSS ************/
.project {
    padding: 80px 0;
    z-index: 10;
    position: relative;
    background-color: black;
}
.tab-content > .active::before {
    border: none;
}
.project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}
.project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}
.project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 0px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}
.project .nav.nav-pills .nav-item {
    width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 1;
}
.project .nav.nav-pills .nav-link::before {
    border-radius: 0;
    border: none;
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-second {
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.custom-button {
    font-size: 14px;
    width: 100%;
    font-weight: 700;
    border: none;
    border-radius: 0;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}
.custom-button.text-only {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    color: white;
    border: 1px solid linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    transition: 0.3s ease-in-out;
}
.custom-button.text-only:hover {
    scale: 1.03;
}
.custom-button.icon-and-text {
    border-radius: 0 0 10px 10px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    transition: 0.3s ease-in-out;
}
.custom-button.icon-and-text:hover {
    color: white;
    background-color: #151515;
}
.custom-button.icon-and-text:active {
    border: 1px solid white;
    background-color: black;
}
.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}
.proj-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 2;
    background-color: #151515;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    transition: background 300ms ease-in-out;
    align-self: stretch;
}
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.card {
    display: flex;
    flex-direction: column;
    background: rgb(128 128 128 / 0.2);
    padding : 3px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    margin: 5px;
    align-self: stretch;
    flex: 32%;
}
.card:hover > .proj-container {
    background: rgb(26, 26, 26, 0.6);
    backdrop-filter: blur(80px);
}
.proj-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    flex: 1;
}
.button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.proj-text {
    line-height: 1;
    margin-bottom: 10px;
}
.proj-text h4 {
    color: white;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.8px;
    margin-bottom: 4px;
}
.proj-text span {
    color: #aaa6a6;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.8px;
}
.blob {
    filter: blur(40px);
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -10px;
    width: calc(50% + 20px);
    height: calc(50% + 20px);
    border-radius: 50%;
    background: rgb(255, 255, 255, 0.5);
}
.fakeblob {
    display: hidden;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
  
/************ Contact CSS ************/
.contact {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 60px 0 200px 0;
}
.contact img {
    width: 92%;
}
.contact h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 30px;
}
.contact form input, .contact form textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
}
.contact form button {
    font-weight: 700;
    color: #000;
    background-color: #fff;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
}
.contact form button span {
    z-index: 1;
    position: relative;
}
.contact form button:hover {
    color: #fff;
}
.contact form button::before {
    content: "";
    background: #121212;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
    width: 100%;
}

/************ Footer CSS ************/
.footer {
    padding: 0 0 50px 0;
    background-image: url('./assets/img/footer-bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.newsletter-bx {
    background: #FFFFFF;
    border-radius: 55px;
    color: #121212;
    padding: 85px 125px;
    margin-bottom: 80px;
    margin-top: -122px;
}
.newsletter-bx h3 {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.2em;
}
.new-email-bx {
    background: #fff;
    padding: 5px;
    border-radius: 8px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.new-email-bx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    border-radius: 8px;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
}
.new-email-bx::after {
    content: "";
    background: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.new-email-bx input {
    width: 100%;
    color: #121212;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0 15px;
}
.new-email-bx button {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    width: 60%;
    padding: 15px 25px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 8px;
    transition: all 0.3s ease-in;
}
.new-email-bx button:hover {
    scale: 0.95;
}
.download-resume {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    margin-bottom: 10px;
    padding: 20px 0px;
    cursor: pointer;
    letter-spacing: 0.5px;
    border-radius: 8px;
    transition: all 0.3s ease-in;
}
.download-resume > button {
    color: white;
    font-weight: 500;
}
.download-resume:hover {
    scale: 0.98;
}
.footer img {
    width: 26%;
}
.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
}






/* Adjust logo size for smaller screens */
@media (max-width: 1499px) {
    .logo img {
        height: 35px;
        width: auto;
        overflow: hidden;
    }
}


/* Modal CSS */


.custom-modal {
    max-width: 1280px;
    width: 90%;
    margin: 50px auto;
}
.modal-header {
    background-color: #121212;
}
.modal-body {
    padding: 50px;
    background-color: rgba(0, 0, 0, 1);
    background-image: url('./assets/img/color-sharp.png');
    background-size: cover;
}
.modal-content {
    border-radius: 20px;
    overflow: hidden;
}
.modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.modal-title h1 {
    font-size: 30px;
    margin-left: 10px;
}
.modal-title svg {
    cursor: pointer;
    scale: 1;
    transition: all 300ms ease-in-out;
}
.modal-title svg:hover {
    scale: 1.1;
}


.wistia-video {
    margin: auto 5px;
    margin-bottom: 10px;
    border-radius: 20px;
    overflow: hidden;
}

.innovation-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.innovation-img {
    height: 300px;
    width: auto;
    margin-top: 50px;
    z-index: -999 !important;
}

.future {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin: 0 10px !important;
}

.future p {
    text-align: left;
    width: 100% !important;
    margin: 0 !important;
}


/* Add this CSS to your stylesheet or a style tag */

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    padding: 10px;
    gap: 10px;
    margin-top: 20px;
}

.image-item {
    width: "auto";
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-item img {
    max-width: 100%;
    max-height: 100%;
}

.skills-icon-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px 5px;
}

@media (max-width: 1200px) {
    nav.navbar a.navbar-brand {
        width: 30%;
        margin-right: 0px;
    }
}
  