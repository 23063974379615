.html-container h1 {
    font-weight: 700;
    margin: 20px 0;
}
.html-container h2,
.html-container p,
.html-container ul,
.html-container ol {
    margin: 20px 0 !important;
}
.html-container ul,
.html-container ol {
    list-style: none;
}
.html-container strong {
    font-weight: 500;
}

.html-container pre {
    margin-top: 10px;
    background-color: #151515;
    color: #d1d5da;
    border-radius: 4px;
    padding: 16px;
    overflow: auto;
}
  
.html-container code {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 14px;
    color: #d1d5da;
    background-color: #151515;
    padding: 5px;
    border-radius: 5px;
}

.text-only-modal {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    color: white;
    border: 1px solid linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%) !important;
    transition: 0.3s ease-in-out;
    font-size: 20px !important;
}
.icon-and-text-modal {
    font-size: 20px !important;
    border-radius: 0;
    background-color: transparent !important;
    border: 1px solid white !important;
    color: white !important;
    transition: 0.3s ease-in-out;
}
.icon-and-text-modal:hover {
    border: 1px solid white !important;
    color: white !important;
    background-color: #151515 !important;
}
.icon-and-text-modal:active {
    border: 1px solid white !important;
    background-color: black !important;
}

.technologies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.technologies > img {
    height: 30px;
    width: auto;
    margin: 2px;
}

.button-container-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0;
}

.login-screen {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-screen > img {
    height: auto;
    width: 70%;
    margin: 20px;
}
.main-screen {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-screen > img {
    height: auto;
    width: 100%;
    margin: 20px;
}
.other-views {
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-views > img {
    margin: 5px;
    border-radius: 5px;
}
.erd {
    display: flex;
    align-items: center;
    justify-content: center;
}
.erd > img {
    height: auto;
    width: 100%;
}

.nft-screenshots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.nft-screenshots > img {
    height: auto;
    width: 50%;
    border-radius: 5px;
}

.deliveroo-images {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.deliveroo-images > img {
    border-radius: 5px;
}

.icon-and-text-modal > svg {
    font-size: 20px;
    margin-right: 10px;
}


@media (max-width: 768px) {
    .html-container h1 {
        margin: 10px 0;
    }
    .html-container h2,
    .html-container p,
    .html-container ul,
    .html-container ol {
        margin: 10px 0 !important;
    }
    .html-container h2 {
        font-size: 18px;
    }
    li {
        font-size: 12px;
    }
    p {
        font-size: 12px;
    }
    .other-views {
        flex-direction: column;
    }
    .nft-screenshots {
        flex-direction: column;
    }
    .nft-screenshots > img {
        width: 60%;
    }
    .button-container-modal {
        margin: 10px 0;
    }
    .modal-title h1 {
        font-size: 20px !important;
    }
    .modal-body {
        padding: 20px !important;
    }
    .deliveroo-images {
        flex-direction: column;
    }
    .deliveroo-images > img {
        width: 60%;
    }
    .technologies > img {
        height: 20px;
    }
    .text-only-modal {
        font-size: 12px !important;
    }
    .icon-and-text-modal {
        font-size: 12px !important;
    }
    .icon-and-text-modal > svg {
        font-size: 12px !important;
        margin-right: 2px !important;
    }
    .html-container h4 {
        font-size: 14px;
    }
}